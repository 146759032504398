import "@fontsource/roboto";

import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import DownloadCSV from "./utils/csvDownloader";
import { FaCircleChevronDown } from "react-icons/fa6";
import Papa from "papaparse";
import moment from 'moment';
import turboCheck from "./utils/turbocheck";

function App() {
  const [file, setFile] = useState(null);
  const [sortMode, setSortMode] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [length, setLength] = useState(0);

  const [validatedEmails, setValidated_emails] = useState([]);
  const [inValidEmails, setInValidEmails] = useState([]);
  const [processingEmails, setProcessingEmails] = useState([]);

  const [validatedCollapsed, setValidatedCollapsed] = useState(true)
  const [processingCollapsed, setProcessingCollapsed] = useState(true)
  const [inValidCollapsed, setInValidCollapsed] = useState(true)

  const [startTime, setStartTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);

  const handleFileChange = async (event) => {
    setFile(event.target.files[0]);
    const emails = await extractEmails(event.target.files[0]);
    setLength(emails.length);
    setValidated_emails([]);
    setInValidEmails([]);
    setEstimatedTime(null);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
    const emails = await extractEmails(event.dataTransfer.files[0]);
    setLength(emails.length);
    setValidated_emails([]);
    setInValidEmails([]);
    setEstimatedTime(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const extractEmails = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      if (file.name.endsWith('.csv')) {
        reader.onload = () => {
          const csvData = reader.result;
          Papa.parse(csvData, {
            header: false,
            skipEmptyLines: true,
            complete: (results) => {
              try {
                const emailList = extractEmailsFromRows(results.data);
                resolve(emailList);
              } catch (error) {
                reject(error);
              }
            },
            error: (error) => {
              reject(error);
            },
          });
        };
        reader.readAsText(file);
      } else if (file.name.match(/\.xlsx?$/)) {
        reader.onload = async () => {
          try {
            const XLSX = await import('xlsx');
            const data = new Uint8Array(reader.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
            const emailList = extractEmailsFromRows(rows);
            resolve(emailList);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        reject(new Error('Unsupported file format'));
      }
    });
  };

  const extractEmailsFromRows = (rows) => {
    const emailList = [];
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    rows.forEach((row) => {
      Object.values(row).forEach((value) => {
        if (typeof value === "string" && emailRegex.test(value.trim())) {
          emailList.push(value.trim());
        }
      });
    });
    return emailList;
  };

  const ValidateEmail = (email) => {
    return new Promise((resolve, reject) => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "OKgRkg0OZI6clEpVRPFHLfO8hgV6P5qbp0rSxvIxJM3P6mgDP30ERl9VIRR48GS4"
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`https://truemail.kaizenapps.com?email=${email}`, requestOptions)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const resp = await response.json();
          // console.log(resp?.success ,'-->>',resp.email)

          setProgress((progress) => {
            return progress + 1;
          });
          if (resp?.success) {
            setValidated_emails((prev) => [
              { email: resp.email, success: resp?.success },
              ...prev,
            ]);
          } else {
            setInValidEmails((prev) => [
              { email: resp.email, success: resp?.success },
              ...prev,
            ]);
          }

          return { email, success: resp?.success };
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  };

  const MAX_CONCURRENT_REQUESTS = 10;

  const validateEmailsInBatches = async (emails) => {
    const results = [];
    const emailBatches = [];

   
    for (let i = 0; i < emails.length; i += MAX_CONCURRENT_REQUESTS) {
      emailBatches.push(emails.slice(i, i + MAX_CONCURRENT_REQUESTS));
    }

    setStartTime(Date.now()); 

    for (const batch of emailBatches) {
      const batchStartTime = Date.now();
      try {
        setProcessingEmails(batch); 
        const batchResults = await Promise.all(
          batch.map((email) => ValidateEmail(email))
        );
        results.push(...batchResults.filter((email) => email !== null));
      } catch (error) {
        console.error("Error in email validation:", error);
      }
      const batchEndTime = Date.now();
      const batchTime = batchEndTime - batchStartTime;
      const averageTimePerEmail = batchTime / batch.length;
      const remainingEmails = length - progress;
      setEstimatedTime(averageTimePerEmail * remainingEmails);
    }

    setProcessingEmails([]); 
    return results;
  };

  useEffect(() => {
    if (estimatedTime !== null) {
      const interval = setInterval(() => {
        setEstimatedTime((prev) => (prev > 1000 ? prev - 1000 : 0));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [estimatedTime]);

  const handleProcessFile = async () => {
    setEstimatedTime(null);
    const emails = await extractEmails(file);

    if (emails.length < 1) {
      toast.error("Couldn't find emails in file");
      return;
    }

    setValidated_emails([]);
    setInValidEmails([]);
    setIsProcessing(true);

    let validated_emails = null;

    if (sortMode) {
      validated_emails = turboCheck(emails);
      if (validated_emails.length < 1) {
        toast.error("No Valid Emails found");
        setProgress(0);
        setIsProcessing(false);
      } else {
        const valid = validated_emails.filter(
          (email) => email.success === true
        );
        const inValid = validated_emails.filter(
          (email) => email.success !== true
        );
        console.log("Valid Emails:", valid);
        console.log("Invalid Emails:", inValid);
        setValidated_emails(valid);
        setInValidEmails(inValid);
        setProgress(0);
        setIsProcessing(false);
      }
    } else {
      validated_emails = turboCheck(emails);
      validated_emails = validated_emails
        .filter((email) => email.success === true)
        .map((email) => email.email);

      validated_emails = await validateEmailsInBatches(validated_emails);
      setProgress(0);
      setIsProcessing(false);
    }
  };



  return (
    <div className=" mx-auto text-center flex flex-col items-center justify-center min-h-screen bg-blue-100 p-5">
      <Toaster />
      <h1 className="text-4xl font-bold mb-6">Kaizen Email Validator</h1>
      <div
        className={`drop-zone w-full max-w-md bg-white border-2 border-dashed border-blue-300 p-12 rounded-lg text-center transition-all duration-300 ease-in-out ${
          file ? "" : "hover:bg-blue-100"
        }`}
        onClick={() => document.getElementById("file-input").click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <p>Drag & drop your CSV or Excel file here or click to upload.</p>
        <input
          type="file"
          id="file-input"
          name="file"
          accept=".csv,.xlsx,.xls"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>
      {file && (
        <div id="file-name" className="mt-4 font-bold text-blue-600">
          Uploaded File: <span>{file.name}</span>
        </div>
      )}

      <div className="toggle-switch-container mt-8 flex flex-col items-center">
        <div className="toggle-label font-bold mb-2">
          Turbo or Deep Validate Mode
        </div>
        <div className="toggle-switch flex items-center gap-4">
          <span>⚡</span>
          <div className="relative inline-block w-14 h-8">
            <span
              onClick={() => setSortMode(!sortMode)}
              className={`slider block cursor-pointer absolute top-0 left-0 right-0 bottom-0 bg-gray-300 transition-all duration-300 rounded-full before:content-[''] before:absolute before:h-6 before:w-6 before:left-1 before:bottom-1 before:bg-white before:rounded-full before:transition-all before:duration-300 ${
                sortMode ? "" : "bg-green-500"
              } ${sortMode ? "" : "before:translate-x-6"}`}
            ></span>{" "}
          </div>
          <span>🔍</span>
        </div>
      </div>
      <button
        onClick={handleProcessFile}
        className={`neomorphic-button mt-8 font-bold py-4 px-10 rounded-lg transition-shadow duration-300 ${
          file
            ? "bg-green-500 text-white cursor-pointer"
            : "bg-gray-400 text-gray-200 cursor-not-allowed"
        }`}
        disabled={!file || isProcessing == true}
      >
        Validate
      </button>
      {isProcessing && (
        <div id="progress" className="mt-6">
          <div className=" h-2 rounded-full w-[250px] sm:w-[400px] overflow-hidden bg-gray-400">
            <div
              style={{
                width: `${(progress / length) * 100}%`,
              }}
              className={`h-full bg-blue-600 `}
            ></div>
          </div>
          <p className="text-lg font-semibold text-blue-700 mt-2">
            {progress}/{length}
          </p>
          {estimatedTime !== null && (
            <p className="text-sm text-gray-600 mt-2">
              Estimated time remaining: {
                (() => {
                  const duration = moment.duration(estimatedTime);
                  const days = Math.floor(duration.asDays());
                  const hours = duration.hours();
                  const minutes = duration.minutes();
                  const seconds = duration.seconds();
                  
                  const parts = [];
                  if (days > 0) parts.push(`${days}d`);
                  if (hours > 0) parts.push(`${hours}h`);
                  if (minutes > 0) parts.push(`${minutes}m`);
                  if (seconds > 0) parts.push(`${seconds}s`);
                  
                  return parts.length > 0 ? parts.join(' ') : '0s';
                })()
              }
            </p>
          )} 
        </div>
      )}
      <div className="flex flex-wrap justify-center items-start  gap-4 mt-8 w-full max-w-4xl">
        {processingEmails.length > 0 && (
          <div className="w-full md:min-w-[30%] md:max-w-[35%] bg-white shadow-md rounded-lg p-4">
            
            <div  onClick={()=>setProcessingCollapsed(!processingCollapsed)} className='flex w-full cursor-pointer items-center justify-between'> <h2 className="font-bold text-blue-600">
              Processing Emails: {processingEmails.length}
            </h2>

            <FaCircleChevronDown className={`text-gray-400 text-xl ${processingCollapsed?'rotate-180':'rotate-0'} duration-300`} />
            </div>
            <ul className={`list-inside h-full duration-300 ${processingCollapsed?'max-h-[0px] min-h-[0px] overflow-hidden':'min-h-[400px] mt-2 max-h-[400px] overflow-y-scroll'} `}>
           
              {processingEmails.map((email, index) => (
                <li
                  key={index}
                  className="text-blue-500 py-1 px-2 bg-blue-50 rounded-md mb-1"
                >
                  {email}
                </li>
              ))}
            </ul>
          </div>
        )}

        {validatedEmails.length > 0 && (
          <div className="w-full md:min-w-[30%] md:max-w-[35%] bg-white shadow-md rounded-lg p-4 ">
           
           <div  onClick={()=>setValidatedCollapsed(!validatedCollapsed)} className='flex w-full cursor-pointer items-center justify-between'> <h2 className="font-bold text-green-600">
              Valid Emails: {validatedEmails.length}
            </h2>

            <FaCircleChevronDown className={`text-gray-400 text-xl ${validatedCollapsed?'rotate-180':'rotate-0'} duration-300`} />
            </div>
            <ul className={`list-inside h-full duration-300 ${validatedCollapsed?'max-h-[0px] min-h-[0px] overflow-hidden':'min-h-[400px] mt-2 max-h-[400px] overflow-y-scroll'} `}>
           
              {validatedEmails.map((email, index) => (
                <li
                  key={index}
                  
                  className="text-green-500 py-1 px-2 bg-green-50 rounded-md mb-1"
                >
                  {email.email}
                </li>
              ))}
            </ul>
          </div>
        )}

        {inValidEmails.length > 0 && (
          <div className="w-full md:min-w-[30%] md:max-w-[35%] bg-white shadow-md rounded-lg p-4">
            <div  onClick={()=>setInValidCollapsed(!inValidCollapsed)} className='flex w-full cursor-pointer items-center justify-between'> <h2 className="font-bold text-red-600">
              Invalid Emails: {inValidEmails.length}
            </h2>

            <FaCircleChevronDown className={`text-gray-400 text-xl ${inValidCollapsed?'rotate-180':'rotate-0'} duration-300`} />
            </div>
            <ul className={`list-inside h-full duration-300 ${inValidCollapsed?'max-h-[0px] min-h-[0px] overflow-hidden':'min-h-[400px] mt-2 max-h-[400px] overflow-y-scroll'} `}>
           
              {inValidEmails.map((email, index) => (
                <li
                  key={index}
                  className="text-red-500 py-1 px-2 bg-red-50 rounded-md mb-1"
                >
                  {email.email}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {(validatedEmails.length > 0 || inValidEmails.length > 0) && (
        <div id="download" className="mt-6">
          <DownloadCSV
            validEmails={validatedEmails}
            invalidEmails={inValidEmails}
            sortMode={sortMode?"TURBO":"DEEP"}
            name={file?.name}
          />
        </div>
      )}
    </div>
  );
}

export default App;


