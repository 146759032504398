import Papa from "papaparse";
import React from "react";
import moment from "moment";
import { saveAs } from "file-saver";

const DownloadCSV = ({ validEmails , invalidEmails , name , sortMode }) => {
  const handleDownload = () => {
   
   
    if(invalidEmails.length>0)
    {
      const inValidData = invalidEmails.map((email) => ({ "email": email.email }));
      const inValidCsv = Papa.unparse(inValidData);
      const blob = new Blob([inValidCsv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${name.split('.').slice(0, -1).join('.')}_${moment().format('YYYY_MM_DD')}_${sortMode}_INVALID.csv`);
    }
    if(validEmails.length>0)
    {
      const validData = validEmails.map((email) => ({ "email": email.email }));
      const validCsv = Papa.unparse(validData);
      const blob = new Blob([validCsv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${name.split('.').slice(0, -1).join('.')}_${moment().format('YYYY_MM_DD')}_${sortMode}_VALID.csv`);
    }
    
  };

  return (
    <button
      onClick={handleDownload}
      style={{
        padding: "10px 20px",
        backgroundColor: "#007BFF",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Download CSV
    </button>
  );
};

export default DownloadCSV;
